import Sidebar from './Sidebar';
import React,{useEffect, useState} from "react";
import { useNavigate, NavLink, Link  } from "react-router-dom";
import UserService from '../services/UserService.js';
import PropertyService from '../services/PropertyService.js';
const BACKOFFICE_URL = "https://backoffice.vedl.no/";


function Avtaler() {
	const navigate = useNavigate();
    const [avtalerList,setAvtalerList] = useState([]);
     const username = localStorage.getItem('username');
         const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');
const [showMenu, setShowMenu] = useState(false);
const closeMobileMenu = () => {
      document.getElementById('left-menu').style.display = 'none';
      setShowMenu(false);
    }
  const openMobileMenu = () => {
      console.log(showMenu);
      {showMenu==false ? document.getElementById('left-menu').style.display = 'block' : document.getElementById('left-menu').style.display = 'none'}
      //document.getElementById('left-menu').style.display = {showMenu=='true' ? 'block' : 'none'};
       setShowMenu(!showMenu);
    }
     const [propertyList, setPropertyList] = useState([]);
     //const [propertyInfo, setPropertyInfo] = useState([]);
     const [propertyInfo, setPropertyInfo] = useState({
      adresse: "",
      postnr: "",
      poststed: ""
    });

     const sendMaintenanceReportEmail = () => {
      UserService.sendMaintenanceReportEmail(propertyInfo, name, email);
     }

    const sendServiceEmail = () => {
      UserService.sendServiceEmail(propertyInfo, name, email);
    }
    

    const changeProperty = (event) => {
      if(event.target.value==="0"){
        return;
      } else {
        localStorage.setItem("propertyid", "")
        localStorage.setItem("propertyid", event.target.value)
      }
      
      PropertyService.propertyInfo(event.target.value).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      PropertyService.avtalerList(event.target.value).then(
        (avtalerlist) => {
          setAvtalerList(avtalerlist);
        },
        (error) => {
          console.log(error);
        }
      );


    }

    useEffect(() => {
    	const loggedin = localStorage.getItem("loggedin");
        if(loggedin!='true'){
          navigate("/login");
        }
      if(localStorage.getItem("buildingid")==="null"){
          return;
        }
        if(localStorage.getItem("propertyid")==="null"){
          return;
        }

        PropertyService.propertyInfo(localStorage.getItem("propertyid")).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching Avtaler
      PropertyService.avtalerList(localStorage.getItem("propertyid")).then(
        (avtalerlist) => {
          setAvtalerList(avtalerlist);
        },
        (error) => {
          console.log(error);
        }
      );

      //fetching users properties
      PropertyService.userPropertyList(username).then(
        (propertylist) => {
          setPropertyList(propertylist);
        },
        (error) => {
          console.log(error);
        }
      );


      

    }, [username])


  return (
    <div>
    <div className="main_container">
    <div className="top-header">
      <div className="header-center">
      <div className="mobile-ham-menu"> 
           <img src="img/ham-menu-icon.png" onClick={openMobileMenu} />
           </div>
          <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="top-search">
              

              <select onChange={changeProperty} defaultValue={localStorage.getItem("propertyid")}>
                  <option key="0" value="0">Velg Eiendom</option>
                {propertyList.map((value, key) => (
                  <option selected={value.companyId===localStorage.getItem("propertyid") ? true : false} onClick={changeProperty} key={key} value={value.companyId}>{value.companyName}</option>
                  ))}
                
              </select>
            </div>
            <div className="profile-area">
              <NavLink to="/profile" title="Profil"><img alt="" src="img/profile-icon.png" height="35" /></NavLink>
              <div className="profile-username">{username}</div>
              <div className="logout-link"><Link className="logout-link" to="/logout">Logout</Link></div>
              </div>
            <div className="clear"></div>
            
        </div>
    </div>
    
    <div className="body-container" id="body-container">
      
        <Sidebar />
        <div className="right-content"  onClick={closeMobileMenu}>
          <div className="content-top">
              <div className="content-top-left"><h1>{propertyInfo.adresse===""  ? "" : propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed}</h1></div>
                <div className="content-top-right">
                  <div className="top-btn"  onClick={() => sendServiceEmail()}><a href="javascript:">Bestill tjenester</a></div>
                  <div className="top-btn" onClick={() => sendMaintenanceReportEmail()}><a href="javascript:">Bestill vedlikeholdsrapport</a></div>
                </div>
                <div className="clear"></div>
            </div>
            
            <div className="right-main-content">
              
              <div className="data-box-04" >
                <div className="servicehefte-box-top"><h1>Avtaler</h1></div>
                  <div className="data-box-content" style={{maxHeight: '700px', overflow: 'auto', border: '0px solid red', width: '100%'}}>
                    <table width="98%" border="0" cellSpacing="0" cellPadding="0" className="db-table" align="center">
                        <tbody>
                        <tr align="left">
                          <th scope="col">Navn</th>
                          <th scope="col">Pris</th>
                          <th scope="col">Leverandør</th>
                          <th scope="col">Beskrivelse</th>
                          <th scope="col">Last ned vedlegg</th>
                          <th scope="col">Slette</th>
                          
                        </tr>
                        <tr>
                          <td colSpan="7">&nbsp;</td>
                        </tr>
                        {avtalerList ? 

                          <>

                          {avtalerList.map((val) => (
                            <><tr className="db-table-tr">
                              <td>{val.navn}</td>
                              <td>{val.pris}</td>
                              <td>{val.leverandor}</td>
                              <td>{val.beskrivelse}</td>
                              <td>{val.web_path ? <a target="_blank" href={BACKOFFICE_URL+''+val.web_path}><img alt="" src="img/icons/document-icon.png" /></a> : 'Ingen vedlegg'}</td>
                                <td><img alt="" src="img/icons/delete-icon.png" /></td>
                              
                            </tr>
                            <tr>
                              <td colSpan="7"><hr /></td>
                            </tr>
                            </>
                          ))} 
                          </> 
                        : ''}
                        
                        </tbody>
                        </table>  
                    </div>
                </div>
                <div className="divider"></div>
                <div className="clear"></div>
                <br />
            </div>
            
        </div>
        
        <div className="clear"></div>
    </div>
    
    
</div>
</div>
    )
}
export default Avtaler;