import axios from "axios";
const API_URL = "https://backoffice.vedl.no/dashboard/dashboardapi/"; //process.env.REACT_APP_API_SERVER_URL;
const BACKOFFICE_URL = "https://backoffice.vedl.no/"; //process.env.REACT_APP_BACKOFFICE_URL;
class PropertyService {
    
    userPropertyList(username) {
        const options = {
            method: "GET",
            url:  API_URL + "eiendommerlist.php?username=" +username
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }
    
    changeBuilding(buildingId) {
        const options = {
            method: "GET",
            url:  API_URL + "bygninger.php?buildingId=" +buildingId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    buildingList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "bygningerlist.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    pendingTask(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "pendingtask.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    taskList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "tasklist.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    propertyInfo(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "eiendommerinfo.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    activityList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "aktiviteter.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }
    
    avtalerList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "avtaler.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    getKunder(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "propertykunde.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    kontakterList(kunderId) {
        const options = {
            method: "GET",
            url:  API_URL + "kontakter.php?kunderid=" +kunderId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    fdvFolderList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "fdvfolder.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    fdvDocumentList(folderid) {
        const options = {
            method: "GET",
            url:  API_URL + "fdv.php?folderid=" +folderid
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    hmsDocumentList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "hms.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    hmsActionList(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "hmsactionslist.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    getActiveReports(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "reportlist.php?propertyid=" +propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    generateReport(propertyId, arstall, areal, kostnad) {
        const options = {
            method: "GET",
            url:  API_URL + "report.php?propertyid="+propertyId+"&arstall="+arstall+"&areal="+areal+"&kostnad="+kostnad
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    getServiceHefteDocs(propertyId) {
        const options = {
            method: "GET",
            url:  API_URL + "servicehefte.php?propertyid="+propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    downloadServiceHefte(propertyId, oppdragnr) {
        const options = {
            method: "GET",
            url:  API_URL + "serviceheftepdf.php?oppdragnr="+oppdragnr+"&propertyid="+propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    downloadServiceHefteAttachment(propertyId, oppdragnr) {
        const options = {
            method: "GET",

            url:  BACKOFFICE_URL + "app/eiendommer/cr_zip.php?compadr="+oppdragnr+"&eiendomid="+propertyId
        }
        return axios(options)
        .then(response => {
            return response.data
        });
    }

    vedlikePlanUpdate(posedDate) {
          
          const options = {
              method: "POST",
              headers: {"Content-Type": "multipart/form-data"},
              data: posedDate,
              url: API_URL + "vedlikeplanupdate.php",
          }  
          return axios(options)
        .then(response => {
            return response.data
        });
        
    }
    
    hmsActionUpdate(posedDate) {

        const options = {
              method: "POST",
              headers: {"Content-Type": "multipart/form-data"},
              data: posedDate,
              url: API_URL + "hmsactionupdate.php",
          }  
          return axios(options)
        .then(response => {
            return response.data
        });

            
          
        
    }

    avtalerUpdate(posedDate) {
          
        const options = {
              method: "POST",
              headers: {"Content-Type": "multipart/form-data"},
              data: posedDate,
              url: API_URL + "avtalerupdate.php",
          }  
          return axios(options)
        .then(response => {
            return response.data
        });
        
    }

}

export default new PropertyService();