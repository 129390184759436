
import {useEffect} from "react";

import { useNavigate  } from "react-router-dom";


function Logout() {
   const navigate = useNavigate();
    
    
    useEffect(() => {
      		localStorage.setItem("loggedin", false);
      		localStorage.setItem("userid", "");
      		localStorage.setItem("username", "");
      		localStorage.setItem("propertyid", "");
      		localStorage.setItem("buildingid", "");
          navigate("/login");

    }, [])


  return (
      <div>
        
      </div>
    

    )
}
export default Logout;