import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./components/HomePage.js";
import Report from "./components/Report.js";
import Fdv from "./components/Fdv.js";
import Hms from "./components/Hms.js";
import Servicehefte from "./components/Servicehefte.js";
import Avtaler from "./components/Avtaler.js";
import Aktiviteter from "./components/Aktiviteter.js";
import Profile from "./components/Profile.js";
import ForgotPassword from "./components/ForgotPassword.js";
import Login from "./components/Login.js";
import Logout from "./components/Logout.js";

import "./App.css";
import "./components/styles/main.css";
import "./components/styles/responsive.css";

function App() {
  return (
    <BrowserRouter>
    <div className="App" id="App-container">
      <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/report" element={<Report />} />
          <Route exact path="/fdv" element={<Fdv />} />
          <Route exact path="/hms" element={<Hms />} />
          <Route exact path="/servicehefte" element={<Servicehefte />} />
          <Route exact path="/avtaler" element={<Avtaler />} />
          <Route exact path="/aktiviteter" element={<Aktiviteter />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/logout" element={<Logout />} />
          
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
