
import React,{useState} from "react";

import { useNavigate  } from "react-router-dom";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import UserService from '../services/UserService.js';

function Login() {
  const [state, setState] = useState({
    username: "",
    password: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
 // console.log(state.username, state.password);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    if(event.keyCode==='13'){
      submitLogin(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
    
    async function submitLogin(event){
      event.preventDefault();

      UserService.login(state.username, state.password).then(
        (userinfo) => {
          console.log(userinfo.code);
          if(userinfo.code==='200'){
            localStorage.setItem("loggedin", true);
            localStorage.setItem("userid", userinfo.id);
            localStorage.setItem("username", userinfo.username);
            localStorage.setItem("password", userinfo.password);
            localStorage.setItem("email", userinfo.email);
            localStorage.setItem("name", userinfo.name);
            localStorage.setItem("propertyid", userinfo.propertyid);
            localStorage.setItem("kunderid", userinfo.kunderid);
            localStorage.setItem("oppdragnr", userinfo.oppdragnr);
            localStorage.setItem("buildingid", userinfo.buildingid);
            navigate("/");
          } else {
             setErrorMessage("Invalid username/password");
          }
          
        },
        (error) => {
         console.log(error);
        }
      );


           
    

      }

  return (
      <div>
        <div className="login-header">
          <div className="header-center">
            <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="clear"></div>
          </div>
        </div>
        <div className="clear"></div>
        <div className="divider"></div>
        <div className="body-container">
          <div className="login-area">
            <div><img src="img/logo-dark.png" alt="Vedl Logo" width="200" /></div>
            <div className="clear"></div>
            <div className="divider"></div>
            <div className="login-heading">
              Login 
            </div>
            <div className="login-input-area">
              

              
              <div className="login-input-section">
                <Form onSubmit={submitLogin} id="login-form" className="login-form" method="POST">
                <div className="login-input">
                  <div><input name="username" type="text" onKeyUp={handleInputChange} onChange={handleInputChange} placeholder="Username" /></div>
                </div>
                <div className="login-input">
                  <div><input name="password" type="password" onKeyUp={handleInputChange} onChange={handleInputChange} placeholder="Password" /></div>
                </div>
                <div className="login-input">
                  <div className="floatleft"><input className="login-submit-button" type="submit" value="Login"  /></div>
                  <div className="floatright"><NavLink to="/forgot-password" title="Glemt passord" className="forgot-password" >Glemt passord?</NavLink></div>
                </div>
                </Form>
                <div className="clear"><br /></div>
                <div className="error-message">
                 {errorMessage ? errorMessage : ''}
                </div>
              </div>

            </div>
            
            
          </div>
        </div>
        <div className="clear"></div>
            <div className="divider"></div>
      </div>
    

    )
}
export default Login;