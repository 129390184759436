
import React,{useState} from "react";

import { useNavigate  } from "react-router-dom";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import UserService from '../services/UserService.js';

function Login() {
  const [state, setState] = useState({
    email: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
 // console.log(state.username, state.password);
  const navigate = useNavigate();
  const handleInputChange = (event) => {
    if(event.keyCode==='13'){
      submitLogin(event);
    }
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
    
    async function submitLogin(event){
      event.preventDefault();
      
      UserService.forgotPassword(state.email).then(
        (userinfo) => {
          
        },
        (error) => {
          setErrorMessage("Invalid username/password");
        }
      );

     

      }

  return (
      <div>
        <div className="login-header">
          <div className="header-center">
            <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="clear"></div>
          </div>
        </div>
        <div className="clear"></div>
        <div className="divider"></div>
        <div className="body-container">
          <div className="login-area">
            <div><img src="img/logo-dark.png" alt="Vedl Logo" width="200" /></div>
            <div className="clear"></div>
            <div className="divider"></div>
            <div className="login-heading">
               Glemt passord
            </div>
            <div className="forgot-input-area">
              

              
              <div className="login-input-section">
                <Form onSubmit={submitLogin} id="login-form" className="forgot-form" method="POST">
                <div className="login-input">
                  <div><input name="email" type="text" onKeyUp={handleInputChange} onChange={handleInputChange} placeholder="Email" /></div>
                </div>
                <div className="login-input">
                  <div className="floatleft"><input className="login-submit-button" type="submit" value="Send"  /></div>
                  <div className="floatright"><NavLink to="/login" title="Login" className="forgot-password" >Login</NavLink></div>
                </div>
                </Form>
                <div className="clear"><br /></div>
                <div className="error-message">
                 {errorMessage ? errorMessage : ''}
                </div>
              </div>

            </div>
            
            
          </div>
        </div>
        <div className="clear"></div>
            <div className="divider"></div>
      </div>
    

    )
}
export default Login;