import axios from "axios";
import qs from 'qs';
const API_URL = "https://backoffice.vedl.no/dashboard/dashboardapi/"; //process.env.REACT_APP_API_SERVER_URL;
class UserService {
    
    forgotPassword(email) {
          const data = {
              email: email
          }
          const options = {
              method: "POST",
              headers: {"Content-Type": "application/x-www-form-urlencoded"},
              data: qs.stringify(data),
              url: API_URL + "forgot.php",
          }  
          return axios(options)
        .then(response => {
            return response.data
        });
        
    }

    login(username, password) {
        const data = {
            username: username,
            password: password
        }
        
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            data: qs.stringify(data),
            url: API_URL + "auth.php",
        } 
        return axios(options)
        .then(response => {
            return response.data
        });
        
    }

    profielUpdate(dataa) {
        const data = dataa
        
        const options = {
              method: "POST",
              headers: {"Content-Type": "application/x-www-form-urlencoded"},
              data: qs.stringify(data),
              url: API_URL + "profileupdate.php",
          }  
        
        return axios(options)
        .then(response => {
            return response.data
        });
        
    }

    signatureUpload (formData){
       
       // Make a POST request using the formData
        return fetch(API_URL + "signatureupload.php", {
          method: 'POST',
          body: formData
        })
          .then(response => {
            return response;
            // Handle response
            //return response;
            //console.log('File uploaded successfully');
          })
          .catch(error => {
            // Handle error
            console.error('Error uploading file:', error);
          });
      
    }

    sendMaintenanceReportEmail(propertyInfo, name, email){

        window.location.href = 'mailto: post@vedlikeholdsforvaltning.no?subject=Bestilling av vedlikeholdsrapport&body=Til vedlikeholdsforvaltning,'+
          '%0D%0A%0D%0AEiendom: '+propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed+
          '%0D%0ABruker: '+name+
          '%0D%0AE-post: '+email+
          '%0D%0A%0D%0ADette er en forespørsel om vedlikeholdsrapport.'+
          '%0D%0A%0D%0AHar du ytteligere kommentarerer?'+
          '%0D%0ASkriv inn her.'+
          '%0D%0A%0D%0AVennlig hilsen,'+
          '%0D%0A'+name+
          '%0D%0A%0D%0A'
    }

    sendServiceEmail(propertyInfo, name, email){

        window.location.href = 'mailto: post@vedlikeholdsforvaltning.no?subject=Bestilling av tjeneste&body=Til Vedlikeholdsforvaltning,'+
      '%0D%0A%0D%0AEiendom: '+propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed+
      '%0D%0ABruker: '+name+
      '%0D%0AE-post: '+email+
      '%0D%0A%0D%0ADette er en henvendelse rundt tjenester. %0D%0A%0D%0AHar du noe å kommentere? %0D%0ASkriv kommentar her.'+
      '%0D%0A%0D%0AMed vennlig hilsen,'+
      '%0D%0A'+name+
      ' %0D%0A%0D%0A'
    }


    

}

export default new UserService();