import {NavLink} from 'react-router-dom';
import {useEffect, useState} from "react";

function Sidebar() {
    
    const [sidebarHeight, setNavigationHeight] = useState(undefined);
    useEffect(() => {
        setNavigationHeight(document.getElementById('body-container').offsetHeight);
    }, []);
    //console.log("sidebarHeight", sidebarHeight);
  return (
    <>
    <div className="left-menu" id="left-menu" style={sidebarHeight < '800' ? {height:'800px'} : {height: sidebarHeight+'px'}} >
        <div>&nbsp;</div>
        <div style={{height: sidebarHeight-50+'px'}} className="sidebar-links">
        <NavLink to="/" title="Home"><div className="left-menu-item"><img alt=""  src="img/icons/01.png" /><br /><span className="sidebar-menu-title">Home</span></div></NavLink>
        <NavLink to="/report" title="Report"><div className="left-menu-item"><img alt=""  src="img/icons/02.png" /><br /><span className="sidebar-menu-title">Report</span></div></NavLink>
        <NavLink to="/hms" title="HMS"><div className="left-menu-item"><img alt=""  src="img/icons/05.png" /><br /><span className="sidebar-menu-title">HMS</span></div></NavLink>
        <NavLink to="/fdv" title="FDV"><div className="left-menu-item"><img alt=""  src="img/icons/04.png" /><br /><span className="sidebar-menu-title">FDV</span></div></NavLink>
        <NavLink to="/avtaler" title="Avtaler"><div className="left-menu-item"><img alt=""  src="img/icons/06.png" /><br /><span className="sidebar-menu-title">Avtaler</span></div></NavLink>
        <NavLink to="/servicehefte" title="Servicehefte"><div className="left-menu-item"><img alt=""  src="img/icons/07.png" /><br /><span className="sidebar-menu-title">Servicehefte</span></div></NavLink>
        <NavLink to="/aktiviteter" title="Aktiviteter"><div className="left-menu-item"><img alt=""  src="img/icons/08.png" /><br /><span className="sidebar-menu-title">Aktiviteter</span></div></NavLink>
        </div>
        
    </div>
    <div className="left-menu" id="left-menu-mobile" style={sidebarHeight < '800' ? {height:'800px'} : {height: sidebarHeight+'px'}} >
        <div>&nbsp;</div>
        <div style={{height: sidebarHeight-50+'px'}} className="sidebar-links">
        <NavLink to="/" title="Home"><div className="left-menu-item"><img alt=""  src="img/icons/01.png" /><br /><span className="sidebar-menu-title">Home</span></div></NavLink>
        <NavLink to="/report" title="Report"><div className="left-menu-item"><img alt=""  src="img/icons/02.png" /><br /><span className="sidebar-menu-title">Report</span></div></NavLink>
        <NavLink to="/hms" title="HMS"><div className="left-menu-item"><img alt=""  src="img/icons/05.png" /><br /><span className="sidebar-menu-title">HMS</span></div></NavLink>
        <NavLink to="/fdv" title="FDV"><div className="left-menu-item"><img alt=""  src="img/icons/04.png" /><br /><span className="sidebar-menu-title">FDV</span></div></NavLink>
        <NavLink to="/avtaler" title="Avtaler"><div className="left-menu-item"><img alt=""  src="img/icons/06.png" /><br /><span className="sidebar-menu-title">Avtaler</span></div></NavLink>
        <NavLink to="/servicehefte" title="Servicehefte"><div className="left-menu-item"><img alt=""  src="img/icons/07.png" /><br /><span className="sidebar-menu-title">Servicehefte</span></div></NavLink>
        <NavLink to="/aktiviteter" title="Aktiviteter"><div className="left-menu-item"><img alt=""  src="img/icons/08.png" /><br /><span className="sidebar-menu-title">Aktiviteter</span></div></NavLink>
        </div>
    </div>
    </>
    )
}
export default Sidebar;