import Sidebar from './Sidebar';
import React, {useEffect, useState, useRef } from "react";
import {NavLink, Link, useNavigate} from 'react-router-dom';
import UserService from '../services/UserService.js';
import PropertyService from '../services/PropertyService.js';

function Profile() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const username = localStorage.getItem('username');
    const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');
    const password = localStorage.getItem('password');
    const [showMenu, setShowMenu] = useState(false);
    const closeMobileMenu = () => {
      document.getElementById('left-menu').style.display = 'none';
      setShowMenu(false);
    }
  const openMobileMenu = () => {
      //console.log(showMenu);
      {showMenu==false ? document.getElementById('left-menu').style.display = 'block' : document.getElementById('left-menu').style.display = 'none'}
      //document.getElementById('left-menu').style.display = {showMenu=='true' ? 'block' : 'none'};
       setShowMenu(!showMenu);
    }
   const [propertyInfo, setPropertyInfo] = useState({
      adresse: "",
      postnr: "",
      poststed: ""
    });

   const [state, setState] = useState({
    id: "",
    fullname: "",
    email: "",
    password:""
  });
   //console.log(state);
  const handleInputChange = event => {
    const { name, value } = event.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

    const sendMaintenanceReportEmail = () => {
      UserService.sendMaintenanceReportEmail(propertyInfo, name, email);
     }

    const sendServiceEmail = () => {
      UserService.sendServiceEmail(propertyInfo, name, email);
    }
    const changeProperty = (event) => {
      if(event.target.value==="0"){
        return;
      } else {

        localStorage.setItem("propertyid", "")
        localStorage.setItem("propertyid", event.target.value)
      }
      
       PropertyService.propertyInfo(event.target.value).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );

      
    }

    useEffect(() => {
    	const loggedin = localStorage.getItem("loggedin");
        if(loggedin!='true'){
          navigate("/login");
        }

        setState({
          id: localStorage.getItem("userid"),
          fullname: name,
          email: email,
          password: password
         });
       
        

        if(localStorage.getItem("buildingid")==="null"){
          return;
        }
        if(localStorage.getItem("propertyid")==="null"){
          return;
        }
       


     PropertyService.propertyInfo(localStorage.getItem("propertyid")).then(
        (propertyinfo) => {
          setPropertyInfo(propertyinfo);
        },
        (error) => {
          console.log(error);
        }
      );
        
        //fetching users properties
      PropertyService.userPropertyList(username).then(
        (propertylist) => {
          setPropertyList(propertylist);
        },
        (error) => {
          console.log(error);
        }
      );

    }, [navigate, username, email, name , password])

    const saveProfile = () => {
          //const data = state
          UserService.profielUpdate(state).then(
          (userinfo) => {
            setSuccessMessage(true);
          },
          (error) => {
            console.log(error);
          }
        );

          
    }
    const [successUploadMessage, setUploadMessage] = useState('');
    const [errorUploadMessage, seterrorUploadMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
      const [previewUrl, setPreviewUrl] = useState('');
  const handleFileChange = (event) => {
      const file = event.target.files[0];
      const fileType = file.type;

      if(fileType!=='image/jpeg' && fileType!=='image/png'  && fileType!=='image/bmp'   && fileType!=='image/gif' ){
        setUploadMessage('');
        seterrorUploadMessage('Last opp en bildefil');
        setPreviewUrl('');
        return;
      } else {
       seterrorUploadMessage(null); 
      }
      setSelectedFile(file);

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreviewUrl('');
      }


  };
    const handleUpload = () => {
      // Perform upload logic here
      if (selectedFile) {
        // Example: Upload the selected file to a server
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('userid', localStorage.getItem("userid"));

          UserService.signatureUpload(formData).then(
          (signinfo) => {
            fileInputRef.current.value = '';
            setSelectedFile(null);
            setPreviewUrl('');
            setUploadMessage('Signaturen ble lastet opp');
          },
          (error) => {
            console.log(error);
          });

          
        
      }
    };

  return (
    <div>
    <div className="main_container">
  	 <div className="top-header">
      <div className="header-center">
          <div className="mobile-ham-menu"> 
           <img src="img/ham-menu-icon.png" onClick={openMobileMenu} />
           </div>
          <div className="logo"><img src="img/logo.png" width="180" height="48" alt="" /></div>
            <div className="top-search">
              

              <select onChange={changeProperty} defaultValue={localStorage.getItem("propertyid")}>
                  <option key="0" value="0">Velg Eiendom</option>
                {propertyList.map((value, key) => (
                  <option selected={value.companyId===localStorage.getItem("propertyid") ? true : false} onClick={changeProperty} key={key} value={value.companyId}>{value.companyName}</option>
                  ))}
                
              </select>
            </div>

            <div className="profile-area">
              <NavLink to="/profile" title="Profil"><img alt="" src="img/profile-icon.png" height="35" /></NavLink>
              <div className="profile-username">{username}</div>
              <div className="logout-link"><Link className="logout-link" to="/logout">Logout</Link></div>
              </div>
            <div className="clear"></div>
            
        </div>
    </div>
    
    <div className="body-container" id="body-container">
    	
        <Sidebar />
        <div className="right-content"  onClick={closeMobileMenu}>
        	<div className="content-top">
            	<div className="content-top-left"><h1>{propertyInfo.adresse===""  ? "" : propertyInfo.adresse+', '+propertyInfo.postnr+' '+propertyInfo.poststed}</h1></div>
                <div className="content-top-right">
                  <div className="top-btn"  onClick={() => sendServiceEmail()}><a href="javascript:">Bestill tjenester</a></div>
                  <div className="top-btn" onClick={() => sendMaintenanceReportEmail()}><a href="javascript:">Bestill vedlikeholdsrapport</a></div>
                </div>
                <div className="clear"></div>
            </div>
            
            <div className="right-main-content">
              <div className="report-help-area">
                <h1>Profil</h1>
              </div>
              
              <div className="report-radio-area">
                <div>
                  <div>
                    <div className="floatleft" style={{width:'75px', lineHeight: '43px'}}><b>Navn:</b></div>
                    <div className="floatleft"><input onKeyUp={handleInputChange} onChange={handleInputChange} name="fullname"  className="profile-input" type="text" value={state.fullname} /></div>
                  </div>
                  <div className="divider"></div>
                  <div className="divider"></div>

                  <div className="clear"></div>
                  <div>
                    <div className="floatleft" style={{width:'75px', lineHeight: '43px'}}><b>Epost:</b></div>
                    <div className="floatleft"><input onKeyUp={handleInputChange} onChange={handleInputChange} name="email"  className="profile-input" type="text" value={state.email} /></div>
                  </div>
                  <div className="divider"></div>
                  <div className="divider"></div>
                  <div className="clear"></div>
                  <div>
                    <div className="floatleft" style={{width:'75px', lineHeight: '43px'}}><b>Passord:</b></div>
                    <div className="floatleft"><input onKeyUp={handleInputChange} onChange={handleInputChange} name="password"  className="profile-input" type="password" value={state.password}/></div>
                  </div>

                </div>
                <div className="divider"></div>
                <div className="clear"></div>
                <div className="divider"></div>
                <div className="clear"></div>
                <div>
                  <button className="profile_button" onClick={() => saveProfile() }>Lagre</button>
                </div>
                <div className="divider"></div>
                <div className="clear"></div>
                {successMessage===true ? <div className="profile-updated">Profilen har blitt oppdatert</div> : ''}
                
              </div>
              <div className="divider"></div>
              <div className="report-help-area displaynone">
                <h1>Signatur</h1>
              </div> 
              <div className="report-radio-area displaynone">
                  <div className="floatleft" >
                    <div className="floatleft" style={{width:'75px', lineHeight: '43px'}}><b>Fil:</b></div>
                    <div className="floatleft">
                      <div>
                        <input type="file" className="profile-input"  onChange={handleFileChange}  ref={fileInputRef} />
                       
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="clear"></div>
                    <div className="divider"></div>
                    <div className="clear"></div>
                    <div>
                      <button className="profile_button" onClick={handleUpload}>Laste opp</button>
                     </div>
                     <div className="divider"></div>
                      <div className="clear"></div>
                      {successUploadMessage ? <div className="profile-updated">{successUploadMessage}</div> : ''}
                      {errorUploadMessage ? <div className="sign-upload-error">{errorUploadMessage}</div> : ''}
                  </div>

                  <div className="floatleft" >
                    <div className="floatleft" style={{width:'15px', lineHeight: '43px'}}>&nbsp;</div>
                    <div className="floatleft">
                      <div>
                        {previewUrl && <img src={previewUrl} alt="Preview" className="previewSignature"  />}
                        
                      </div>
                    </div>
                  </div>
                  
                  
                <div className="clear"></div>
              </div>
              <div className="clear"></div>

            </div>
           
        </div>
        
        <div className="clear"></div>
    </div>
    
    
</div>
</div>
    )
}
export default Profile;